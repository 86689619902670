<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-07 15:22:51
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-07 19:35:50
-->
<template>
  <div class="WatchGatewayInfo">
    <div class="model"></div>
    <div class="main">
      <span class="close el-icon el-icon-close" @click="close"></span>
      <div class="head">网关详情</div>
      <div class="form">
        <div v-for="(item, index) in listInfo" :key="index">
          <div class="lable">
            {{ item.lable }} <span v-if="item.isMust" class="red">*</span>
          </div>
          <div class="input">
            <el-input
              v-model="form[item.input]"
              placeholder="请输入内容"
              size="small"
              :disabled="item.disabled"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="foot">
        <el-button plain size="small" class="cancelBtn" @click="close"
          >取消</el-button
        >
        <el-button type="primary" plain size="small" @click="submintF"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { update } from "@/api/gateway.js";
export default {
  name: "WatchGatewayInfo",
  components: {},
  props: {
    info: {
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      listInfo: [
        {
          lable: "网关名称",
          input: "gatewayName",
          disabled: true,
          isMust: true,
        },
        { lable: "创建时间", input: "createTime", disabled: true },
        { lable: "MAC地址", input: "mac", disabled: true },
        { lable: "最近更新", input: "updateTime", disabled: true },
        { lable: "网关唯一编号（uid）", input: "name", disabled: true },
        { lable: "连接传感器协议", input: "name", disabled: true },
        { lable: "IP地址", input: "name", disabled: true },
        { lable: "ZigBee物理通道", input: "name", disabled: true },
        { lable: "软件版本", input: "name", disabled: true },
        { lable: "ZigBee网络号", input: "name", disabled: true },
        { lable: "硬件版本", input: "name", disabled: true },
        { lable: "4G卡号", input: "imsi", disabled: true },
        { lable: "已使用/剩余4G流量", input: "name", disabled: true },
        { lable: "连接状态", input: "isOnline", disabled: true },
      ],
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {
    console.log(">>>>>>>>>>>", this.info);
    this.init();
  },
  methods: {
    init() {
      this.form = {
        ...this.info,
      };
      let Time = this.$formatDateTime(
        this.form.createTime,
        "yyyy-MM-dd HH:mm:ss"
      );
      let Time2 = this.$formatDateTime(
        this.form.updateTime,
        "yyyy-MM-dd HH:mm:ss"
      );
      this.$set(this.form, "createTime", Time);
      this.$set(this.form, "updateTime", Time2);
      this.$set(
        this.form,
        "isOnline",
        this.form.isOnline == 0 ? "未连接" : "已连接"
      );
    },
    close(data) {
      this.$emit("close", data);
    },
    submintF() {
      let data = {
        gatewayId: this.form.gatewayId,
        gatewayName: this.form.gatewayName,
        mac: this.form.mac,
      };
      console.log(">>>>>>>", this.form.gatewayName == "");
      if (this.form.gatewayName == "") {
        this.$message({
          message: "网关名称不能为空",
          type: "error",
        });
        return;
      }
      update(data).then((res) => {
        if (this.apiReply(res)) {
          this.$message({
            message: "更新完成",
            type: "success",
          });
          this.$emit("close", "update");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.red {
  color: #f15a4f;
}
.WatchGatewayInfo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  .model {
    position: fixed;
    @extend .model;
  }
  .main {
    width: 827px;

    background: #fff;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 28px;
    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
      &:hover {
        color: $main_color;
      }
    }
    .head {
      @extend .title_green;
    }
    .form {
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        width: 49%;
        .lable {
          font-size: 14px;
          text-align: left;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #232626;
          height: 34px;
          line-height: 34px;
        }
      }
    }
    .foot {
      text-align: right;
    }
  }
}
</style>
