/*
 * @Description: 传感器
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-08 15:35:51
 * @LastEditors: 支磊
 * @LastEditTime: 2021-10-22 11:31:31
 */
import base from '@/config'; // 导入接口域名列表
import axios from '@/utils/cmshttps'; // 导入http中创建的axios实例
import qs from 'qs'

function headerF() {
  let header = {}
  if (localStorage.getItem("SiteId") && localStorage.getItem("SiteId") != -1) {
    header = {
      'SiteId': localStorage.getItem("SiteId")
    }
  }
  return header
}
export function list(data) {
  let header = headerF() //传感器信息
  return axios.get(`${base.cmsBaseApi}/sensor/v2/list?pageNo=${data.pageNo}&pageSize=${data.pageSize}&searchString=${data.searchString}`, {
    headers: {
      ...header
    }
  });
}
export function gwDetailList(data) {
  let header = headerF() //传感器列表
  return axios.get(`${base.cmsBaseApi}/sensor/v2/gwDetailList?pageNo=${data.pageNo}&pageSize=${data.pageSize}&searchString=${data.searchString}&gatewayId=${data.gatewayId}`, {
    headers: {
      ...header
    }
  });
}
export function detail(data) {
  let header = headerF() //传感器详情
  return axios.get(`${base.cmsBaseApi}/sensor/v2/detail?id=${data}`, {
    headers: {
      ...header
    }
  });
}
export function update(data) {
  let header = headerF() //传感器详情
  return axios.put(`${base.cmsBaseApi}/sensor/v2/update`, data, {
    headers: {
      ...header
    }
  });
}