<!--
 * @Description: 连接的传感器
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-07 20:11:16
 * @LastEditors: 支磊
 * @LastEditTime: 2021-09-27 10:06:46
-->
<template>
  <div class="WatchSensorInfo">
    <div class="model"></div>
    <div class="main" v-show="!infoShow">
      <span class="close el-icon el-icon-close" @click="close"></span>
      <div class="head">连接的传感器</div>
      <div class="table">
        <el-table :data="tableData" style="width: 100%" height="100%">
          <el-table-column prop="sensorTypeName" label="传感器类型">
          </el-table-column>
          <el-table-column align="center" prop="serialNo" label="MAC地址">
          </el-table-column>

          <el-table-column align="center" prop="power" label="电池电量">
          </el-table-column>
          <el-table-column align="center" label="最近上报时间">
            <template slot-scope="scope">
              {{ scope.row.latestUploadTime | time }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="address"
            label="操作"
            width="120px"
          >
            <template slot-scope="scope">
              <i
                class="ql-iconchakan iconfont iconchakan"
                @click="watchInfo(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNo"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="main" v-show="infoShow">
      <span class="close el-icon el-icon-close" @click="close"></span>
      <p @click="infoShow = false" class="backBtn">
        <span class="iconfont ql-iconfanhui1"></span>
        <span class="back">返回</span>
      </p>
      <div class="head" style="margin-top: 10px">基本信息</div>
      <div class="form">
        <div v-for="(item, index) in form1" :key="'info1' + index">
          <div class="lable">{{ item.lable }}</div>
          <div class="input">
            <el-input
              v-model="form[item.input]"
              placeholder=""
              size="small"
              :disabled="item.disabled"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="head" style="margin-top: 10px">无线配置</div>
      <div class="form">
        <div v-for="(item, index) in form2" :key="'info2' + index">
          <div class="lable">{{ item.lable }}</div>
          <div class="input">
            <el-input
              v-model="form[item.input]"
              placeholder=""
              size="small"
              :disabled="item.disabled"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="head" style="margin-top: 10px">采样配置</div>
      <div class="form">
        <div v-for="(item, index) in form3" :key="'info2' + index">
          <div class="lable">{{ item.lable }}</div>
          <div class="input">
            <el-select
              style="width: 100%"
              v-model="form[item.input]"
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in item.option"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="foot">
        <el-button
          plain
          size="small"
          class="cancelBtn"
          @click="infoShow = false"
          >取消</el-button
        >
        <el-button type="primary" plain size="small" @click="submintF"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { gwDetailList, detail, update } from "@/api/sensor.js";
export default {
  name: "WatchSensorInfo",
  components: {},
  props: {
    info: {},
  },
  filters: {
    time(data) {
      let time = "";
      let format = "yyyy-MM-dd HH:mm:ss";
      var t = new Date(data);
      var tf = function (i) {
        return (i < 10 ? "0" : "") + i;
      };
      time = format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        let date = "";
        switch (a) {
          case "yyyy":
            date = tf(t.getFullYear());
            break;
          case "MM":
            date = tf(t.getMonth() + 1);
            break;
          case "mm":
            date = tf(t.getMinutes());
            break;
          case "dd":
            date = tf(t.getDate());
            break;
          case "HH":
            date = tf(t.getHours());
            break;
          case "ss":
            date = tf(t.getSeconds());
            break;
        }
        return date;
      });
      return time;
    },
  },
  data() {
    let rawDataIntervalOptions = [
      { value: 1 * 60 * 60, label: "1小时" },
      { value: 2 * 60 * 60, label: "2小时" },
      { value: 4 * 60 * 60, label: "4小时" },
      { value: 8 * 60 * 60, label: "8小时" },
      { value: 24 * 60 * 60, label: "24小时" },
      { value: 48 * 60 * 60, label: "48小时" },
    ];
    let eigenvalueUploadIntervalOptions = [
      { value: 15 * 60, label: "15分钟" },
      { value: 30 * 60, label: "30分钟" },
      { value: 1 * 60 * 60, label: "1小时" },
      { value: 2 * 60 * 60, label: "2小时" },
      { value: 4 * 60 * 60, label: "4小时" },
      { value: 8 * 60 * 60, label: "8小时" },
      { value: 16 * 60 * 60, label: "16小时" },
      { value: 24 * 60 * 60, label: "24小时" },
    ];
    let sampleLenOptions = [
      { value: 1024, label: "1024" },
      { value: 2048, label: "2048" },
    ];
    let sampleFreqOptions = [
      { value: 6660, label: "6660 Hz" },
      { value: 3330, label: "3330 Hz" },
      { value: 1660, label: "1660 Hz" },
      { value: 833, label: "833 Hz" },
    ];
    return {
      tableData: [],
      total: 0, //总条数
      pageSize: 10,
      pageNo: 1,
      searchString: "",
      infoShow: false,
      form: {},
      form1: [
        //基础信息
        { lable: "传感器类型", input: "sensorTypeName", disabled: true },
        { lable: "软件版本", input: "swVersion", disabled: true },
        { lable: "电池电量", input: "power", disabled: true },
        { lable: "硬件版本", input: "hwVersion", disabled: true },
        { lable: "创建时间", input: "createTime", disabled: true },
        { lable: "最近更新", input: "latestConfigUploadTime", disabled: true },
      ],
      form2: [
        // 无线配置
        { lable: "工作模式", input: "workMode", disabled: true },
        { lable: "网络地址", input: "networkAddress", disabled: true },
        { lable: "Zigbee物理通道", input: "physicalChannel", disabled: true },
        { lable: "Zigbee网络号", input: "networkNumber", disabled: true },
      ],

      form3: [
        {
          lable: "波形上报周期",
          input: "rawDataInterval",
          option: rawDataIntervalOptions,
        },
        {
          lable: "特征值上报周期",
          input: "eigenvalueUploadInterval",
          option: eigenvalueUploadIntervalOptions,
        },
        { lable: "采样长度", input: "sampleLen", option: sampleLenOptions },
        { lable: "采样频率", input: "sampleFreq", option: sampleFreqOptions },
      ],
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {
    this.getTableDataF();
  },
  methods: {
    submintF() {
      let data = {
        eigenvalueUploadInterval: this.form.eigenvalueUploadInterval,
        rawDataInterval: this.form.rawDataInterval,
        sampleFreq: this.form.sampleFreq,
        sampleLen: this.form.sampleLen,
        sensorId: this.form.sensorId,
      };
      update(data).then((res) => {
        if (this.apiReply(res)) {
          this.infoShow = false;
          this.getTableDataF();
          console.log(res.data.data);
        }
      });
      console.log("提交");
    },
    getDetailF(data) {
      detail(data.sensorId).then((res) => {
        if (this.apiReply(res)) {
          this.form = {
            sensorTypeName: data.sensorTypeName,
            ...res.data.data,
          };
          let time = this.$formatDateTime(
            this.form.createTime,
            "yyyy-MM-dd HH:mm:ss"
          );
          this.form.latestConfigUploadTime = this.$formatDateTime(
            data.latestConfigUploadTime,
            "yyyy-MM-dd HH:mm:ss"
          );
          this.form.createTime = time;
          console.log(">>>>>>>>>>>>", this.form);
        }
      });
    },
    watchInfo(data) {
      // 查看详情
      this.infoShow = true;
      this.getDetailF(data);
    },
    getTableDataF() {
      // 获取传感器列表数据
      console.log("this.info", this.info);
      gwDetailList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        searchString: this.searchString,
        gatewayId: this.info.gatewayId,
      }).then((res) => {
        if (this.apiReply(res)) {
          this.tableData = [...res.data.data.dataList];
          this.total = res.data.data.total;
        }
      });
    },
    close(data) {
      this.$emit("close", data);
    },
    handleSizeChange(val) {
      this.getTableDataF();
    },
    handleCurrentChange(val) {
      this.getTableDataF();
    },
  },
};
</script>
<style lang="scss" scoped>
.iconchakan {
  cursor: pointer;
  &:hover {
    color: $main_color;
  }
}
.WatchSensorInfo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  .model {
    position: fixed;
    @extend .model;
  }
  .main {
    width: 827px;
    height: 712px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 28px;
    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
      &:hover {
        color: $main_color;
      }
    }
    .backBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
      .back {
        font-size: 14px;
      }
      &:hover {
        color: $main_color;
      }
    }
    .form {
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        width: 49%;
        .lable {
          font-size: 14px;
          text-align: left;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #232626;
          height: 34px;
          line-height: 34px;
        }
      }
    }
    .foot {
      text-align: right;
    }
    .table {
      height: 600px;
      padding-top: 20px;
    }
    .head {
      @extend .title_green;
    }
    .page {
      padding-top: 20px;
    }
  }
}
</style>
