<!--
 * @Description: 新增网关
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-24 11:25:19
 * @LastEditors: 支磊
 * @LastEditTime: 2021-07-12 11:21:11
-->
<template>
  <div class="AddGatewayComponents" @click="ceshiF">
    <div class="model"></div>
    <div class="main">
      <span class="close el-icon el-icon-close" @click="close"></span>
      <div class="head">新增网关</div>
      <div class="form">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="form"
          label-position="top"
          class="demo-ruleForm"
        >
          <el-form-item label="网关名称" prop="gatewayName">
            <el-input v-model="ruleForm.gatewayName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="MAC地址" prop="mac">
            <el-input v-model="ruleForm.mac" size="small"></el-input>
          </el-form-item>
          <el-form-item label="选择分组" prop="group">
            <el-select
              style="width: 100%"
              v-model="groupId"
              filterable
              placeholder="请选择"
              size="small"
            >
              <el-option
                v-for="item in groupOptions"
                :key="item.uId"
                :label="item.name"
                :value="item.uId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="foot">
          <el-button plain size="small" class="cancelBtn" @click="close"
            >取消</el-button
          >
          <el-button type="primary" plain size="small" @click="submintF"
            >提交</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { create } from "@/api/gateway.js";
import { addGateway } from "@/api/basics.js"; //轻能接口
export default {
  name: "AddGatewayComponents",
  components: {},
  props: {
    node: {},
    groupOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    var validateGatewayName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("网关名称不能为空"));
      } else {
        callback();
      }
    };
    return {
      groupId: "",
      rules: {
        gatewayName: [
          { validator: validateGatewayName, trigger: "blur", required: true },
        ],
      },
      ruleForm: {
        gatewayName: "",
        mac: "",
      },
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {
    if (this.groupOptions.length != 0) {
      this.groupId = this.groupOptions[0].uId;
    }
  },
  methods: {
    ceshiF() {
      console.log(this.groupOptions);
    },
    close(data) {
      this.$emit("close", data);
    },
    submintF() {
      // 提交
      let gatewayId = "";
      this.$refs.form.validate((valid) => {
        if (valid) {
          create(this.ruleForm).then((res) => {
            if (this.apiReply(res)) {
              let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
              let apiData = {
                enterpriseId: WORK_APPLY.enterpriseId, //企业id
                gatewayId: res.data.data.gatewayId, //霍尼韦尔的网关id
                uId: this.groupId, //轻能分组uId
                applyId: WORK_APPLY.id, //物联应用id
              };
              gatewayId = res.data.data.gatewayId;
              addGateway(apiData).then((res) => {
                if (this.ApiReply(res)) {
                  this.$message({
                    message: "已添加",
                    type: "success",
                  });
                  this.close(gatewayId);
                }
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.AddGatewayComponents {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  .model {
    position: fixed;
    @extend .model;
  }
  .main {
    width: 384px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 28px;
    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
      &:hover {
        color: $main_color;
      }
    }
    .head {
      @extend .title_green;
    }
    .form {
      padding-top: 20px;
      /deep/.el-form-item__label {
        line-height: 1;
        text-align: left;
        width: 100%;
      }
    }
    .foot {
      text-align: right;
    }
  }
}
</style>
