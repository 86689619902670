<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-31 15:02:36
 * @LastEditors: 支磊
 * @LastEditTime: 2021-11-23 10:18:16
-->
<template>
  <div class="GatewayTableComponents">
    <el-table :data="tableData" style="width: 100%" height="100%">
      <el-table-column prop="gatewayName" label="网关名称"> </el-table-column>
      <el-table-column align="center" prop="mac" label="MAC地址">
      </el-table-column>
      <el-table-column align="center" prop="status" label="连接传感器协议">
        <template slot-scope="scope">
          {{ scope.row.status }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="最近上报时间">
        <template slot-scope="scope">
          {{ scope.row.updateTime | time }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="address" width="120px" label="操作">
        <template slot-scope="scope">
          <div class="options">
            <el-tooltip
              class="item"
              effect="dark"
              content="连接的传感器"
              placement="top-start"
            >
              <i
                class="iconfont ql-iconchuanganqi"
                @click="sensorF(scope.row)"
                style="margin-right: 10px"
              ></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="网关"
              placement="top-start"
            >
              <i
                class="iconfont"
                style="margin-right: 10px"
                @click="watchF(scope.row)"
                :class="
                  scope.row.isOnline == 0 ? 'ql-iconnotOnLine' : 'ql-icononline'
                "
              ></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "GatewayTableComponents",
  components: {},
  props: {
    tableData: {},
  },
  data() {
    return {};
  },
  filters: {
    time(data) {
      let time = "";
      let format = "yyyy-MM-dd HH:mm:ss";
      var t = new Date(data);
      var tf = function (i) {
        return (i < 10 ? "0" : "") + i;
      };
      time = format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        let date = "";
        switch (a) {
          case "yyyy":
            date = tf(t.getFullYear());
            break;
          case "MM":
            date = tf(t.getMonth() + 1);
            break;
          case "mm":
            date = tf(t.getMinutes());
            break;
          case "dd":
            date = tf(t.getDate());
            break;
          case "HH":
            date = tf(t.getHours());
            break;
          case "ss":
            date = tf(t.getSeconds());
            break;
        }
        return date;
      });
      return time;
    },
  },
  computed: {},
  //监听执行
  watch: {},
  mounted() {},
  methods: {
    deleteF(data) {
      // 删除网关
      this.$emit("options", "delete", data);
    },
    watchF(data) {
      // 查看网关详情
      this.$emit("options", "watch", data);
    },
    sensorF(data) {
      // 传感器
      this.$emit("options", "sensor", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.iconfont {
  cursor: pointer;
  &:hover {
    color: $main_color;
  }
}
.svgstyle {
  &:hover {
    color: $main_color;
  }
}
.options {
  display: flex;
  align-items: center;
  justify-content: center;
  > i {
    font-size: 14px;
  }
}
.GatewayTableComponents {
  height: calc(100% - 135px);
  overflow: auto;
  .delete {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    color: $delete_color;
  }
}
</style>
