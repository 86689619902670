<!--
 * @Description: 网关
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-22 15:03:39
 * @LastEditors: 支磊
 * @LastEditTime: 2021-11-23 10:17:31
-->
<template>
  <div class="gateway">
    <div class="main">
      <!-- left -->
      <div class="navList" :style="foldStyle">
        <leftNav :treeData="groupList" @node="activeNodeF" @emitF="emitLeft" />
      </div>
      <!-- left end -->
      <!-- right -->
      <div class="details">
        <div class="on_off">
          <i
            class="iconfont"
            :class="foldFlag ? 'ql-icondanchunew' : 'ql-iconshouqinew'"
            @click="foldF"
          ></i>
        </div>
        <div class="listBox">
          <div class="head">
            <p>{{ node.name }}</p>
          </div>
          <div class="option">
            <div>
              <el-input
                style="width: 450px"
                placeholder="请输入网关名称"
                v-model="searchString"
                size="small"
                prefix-icon="el-icon-search"
                @input="inputF"
              >
              </el-input>
            </div>
          </div>
          <!-- 内容主体 -->
          <table-c :tableData="datalist" @options="optionsF" />
          <!-- 内容主体 end -->
          <!-- 分页 -->
          <div class="page">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :key="total"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <!-- 分页 end -->
        </div>
      </div>
      <!-- right end -->
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="425px"
    >
      <div
        class="dialogtitle"
        slot="title"
        v-text="isDialogStyle == 0 ? '添加分组' : '编辑分组'"
      ></div>
      <p style="margin-bottom: 10px">分组名称</p>
      <div>
        <el-input size="small" v-model="groupnameVal"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="dialogVisible = false"
          size="small"
          class="cancelcancel"
          >取 消</el-button
        >
        <el-button type="primary" @click="submint()" size="small"
          >完 成</el-button
        >
      </span>
    </el-dialog>
    <!-- 弹窗end -->
    <!-- 添加 -->
    <addDialog
      v-if="addShow"
      @close="closeAddF"
      :node="node"
      :groupOptions="groupOptions"
    />
    <!-- 添加 end -->
    <!-- 查看信息 -->
    <infoDialog v-if="infoShow" @close="closeInfoF" :info="info"></infoDialog>
    <!-- 查看信息 end -->
    <!-- 传感器 -->
    <sensorDialog
      v-if="sensorShow"
      @close="sensorF"
      :info="info"
    ></sensorDialog>
    <!-- 传感器 end -->
  </div>
</template>

<script>
import { list, deleteApi } from "@/api/gateway.js";
import {
  queryGatewayGroup,
  createGatewayGroup,
  updateGatewayGroup,
  removeGatewayGroup,
} from "@/api/basics.js"; //轻能接口
import leftNav from "./private/GatewayLeftComponents"; //左侧目录
import tableC from "./private/GatewayTableComponents"; //设备列表表格样式
import addDialog from "./private/AddGatewayComponents";
import infoDialog from "./private/WatchGatewayInfo"; //查看设备信息
import sensorDialog from "./private/WatchSensorInfo"; //查看设备信息

export default {
  name: "gateway",
  components: { leftNav, tableC, addDialog, infoDialog, sensorDialog },
  data() {
    return {
      searchString: "", //搜索
      list: [],
      foldFlag: false, //折叠标识
      pageSize: 10,
      currentPage: 1,
      foldStyle: {
        width: "248px",
        padding: "16px",
      },
      node: {
        name: "所有网关",
      },
      showType: 1,
      groupList: [], //网关分组列表
      isDialogStyle: 0,
      dialogVisible: false,
      groupnameVal: "",
      editId: "",
      addShow: false, //是否显示添加网关弹窗
      dataList: [], //数据
      total: 0,
      datalist: [],
      timeF: null,
      infoShow: false, //网关详情显示状态
      info: {}, //网关详情
      sensorShow: false, //传感器弹窗显示状态
    };
  },

  computed: {
    groupOptions() {
      let rootGroup = JSON.parse(JSON.stringify(this.groupList[0]));
      delete rootGroup.chil;
      let groupOptions = [{ ...rootGroup }, ...this.groupList[0].chil];
      return groupOptions;
    },
  },
  //监听执行
  watch: {},
  mounted() {
    this.updategetqueryGatewayGroupF();
  },
  methods: {
    sensorF() {
      this.sensorShow = false;
    },
    closeInfoF(data) {
      this.infoShow = false;
      if (data) {
        this.getListDataF();
      }
    },
    inputF() {
      clearTimeout(this.timeF);
      this.timeF = setTimeout(() => {
        this.getListDataF();
      }, 1200);
    },
    optionsF(type, data) {
      console.log(type, data);
      switch (type) {
        case "delete":
          this.deleteF(data);
          break;
        case "watch":
          this.watchInfoF(data);
          break;
        case "sensor":
          this.watchSensorF(data);
          break;
        default:
          break;
      }
    },
    watchSensorF(data) {
      // 查看传感器
      this.info = {
        ...data,
      };
      this.sensorShow = true;
    },
    watchInfoF(data) {
      // 查看网关详情
      this.info = {
        ...data,
      };
      this.infoShow = true;
      console.log(data);
    },
    deleteF(data) {
      // 删除
      this.$confirm(
        "您将要删除所选中的网关！网关一旦删除将无法恢复！您确认要永久删除所选中的网关吗？",
        "删除网关",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteApi({ ids: [data.gatewayId] }).then((res) => {
            if (this.apiReply(res)) {
              this.$message({
                message: "已删除",
                type: "success",
              });
              this.getListDataF();
            }
          });
        })
        .catch(() => {});
    },
    datalistF() {
      let arr = this.node.gatewayId ? this.node.gatewayId.split(",") : [];
      let Arr = [];
      if (arr.length == 0) {
        Arr = [...this.dataList];
      } else {
        for (let i = 0; i < this.dataList.length; i++) {
          if (arr.indexOf(this.dataList[i].gatewayId + "") != -1) {
            Arr.push(this.dataList[i]);
          }
        }
      }

      this.datalist = Arr.splice(
        this.pageSize * (this.currentPage - 1),
        this.pageSize * this.currentPage
      );

      this.total = this.datalist.length;
    },
    submint() {
      // 创建/编辑 分组
      if (this.isDialogStyle == 0) {
        // 创建
        this.createGatewayGroupF();
      } else {
        // 编辑
        this.updateGatewayGroupF();
      }
    },
    updateGatewayGroupF() {
      // 编辑分组
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      let node = this.groupList[0];
      let data = {
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        uId: this.editId, //修改的菜单uId
        name: this.groupnameVal, //菜单名
        split: node.split, //父菜单的split
        applyId: WORK_APPLY.id, //物联应用id
      };
      updateGatewayGroup(data).then((res) => {
        if (this.ApiReply(res)) {
          this.$message({
            message: "已修改",
            type: "success",
          });
          this.dialogVisible = false;
          this.getqueryGatewayGroupF();
        }
      });
    },
    createGatewayGroupF() {
      // 添加分组
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      let node = this.groupList[0];
      let data = {
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        parentId: node.uId, //父菜单uId
        name: this.groupnameVal, //菜单名
        split: node.split, //父菜单的split
        level: 0, //父菜单的level
        applyId: WORK_APPLY.id, //物联应用id
      };
      createGatewayGroup(data).then((res) => {
        if (this.ApiReply(res)) {
          this.$message({
            message: "创建成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getqueryGatewayGroupF();
        }
      });
    },
    getqueryGatewayGroupF() {
      // 获取分组接口数据
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      queryGatewayGroup({
        applyId: WORK_APPLY.id,
        enterpriseId: WORK_APPLY.enterpriseId,
      }).then((res) => {
        this.groupList = [...res.data.data.list];
        if (!this.node.gatewayId) {
          this.node = {
            ...this.groupList[0],
          };
        }
      });
    },
    deletegroupF(node) {
      // 删除分组
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      let data = {
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        uId: node.uId, //记录的uId
        parentId: node.parentId, //
        sort: node.sort, //
        applyId: WORK_APPLY.id, //物联应用id
      };
      removeGatewayGroup(data).then((res) => {
        if (this.ApiReply(res)) {
          this.$message({
            message: "已删除",
            type: "success",
          });
          this.getqueryGatewayGroupF();
        }
      });
    },
    addnewdevice() {
      // 导入网关
      this.addShow = true;
    },
    closeAddF(data) {
      this.addShow = false;
      if (data) {
        this.node.gatewayId = this.node.gatewayId + data + ",";
        this.updategetqueryGatewayGroupF();
      }
    },
    updategetqueryGatewayGroupF() {
      // 获取分组接口数据
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      queryGatewayGroup({
        applyId: WORK_APPLY.id,
        enterpriseId: WORK_APPLY.enterpriseId,
      }).then((res) => {
        this.groupList = [...res.data.data.list];
        this.getListDataF();
      });
    },
    command(data) {
      this.showType = data;
    },
    activeNodeF(data) {
      console.log("data", data);
      this.node = {
        ...data,
      };
      this.datalistF();
    },

    emitLeft(type, data) {
      // 左侧目录触发的方法
      switch (type) {
        case "add":
          this.isDialogStyle = 0;
          this.dialogVisible = true;
          break;
        case "delate":
          // 删除
          this.$confirm("此操作将永久删除该分组, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.deletegroupF(data);
            })
            .catch(() => {});
          break;
        case "edit":
          this.isDialogStyle = 1;
          this.dialogVisible = true;
          this.editId = data.uId;
          this.groupnameVal = data.name;
          break;
        default:
          break;
      }
    },
    getListDataF() {
      // 获取网关列表
      let data = {
        pageNo: "1",
        pageSize: "500",
        searchString: this.searchString,
      };
      list(data).then((res) => {
        if (this.apiReply(res)) {
          this.dataList = res.data.data.dataList;
          console.log("<<<<<<<<<<<<", this.dataList);
          this.datalistF();
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    foldF() {
      this.foldFlag = !this.foldFlag;
      if (this.foldFlag) {
        this.foldStyle.width = "0";
        this.foldStyle.padding = "0";
      } else {
        this.foldStyle.width = "248px";
        this.foldStyle.padding = "16px";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
}
.gateway {
  height: 100%;
  width: 100%;
  .main {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    // 左侧目录
    .navList {
      background: #fff;
      width: 248px;
      height: 100%;
      padding: 16px;
      border-radius: 5px;
      box-shadow: 0 4px 6px 0 rgba(26, 20, 61, 0.1);
      transition: all 0.5s ease;
      overflow: hidden;
      transition: all 0.5s ease;
      &:hover {
        overflow: scroll;
        overflow-x: hidden;
      }
    }
    .listBox {
      border-radius: 5px;
      background: #fff;
      height: 100%;
      // box-shadow: 0 4px 6px 0 rgba(26, 20, 61, 0.1);
      box-shadow: 0 0 5px rgba(31, 35, 41, 0.1);
      padding: 24px 44px;
      min-width: 890px;
      .head {
        height: 24px;
        line-height: 24px;
        padding-left: 20px;
        color: #1f2329;
        font-size: 16px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          top: 4px;
          left: 0;
          border-left: 4px solid $main_color;
          height: 16px;
        }
        p {
          text-align: left;
        }
      }
      .option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 15px 0;
        > div {
          display: flex;
          align-items: center;
        }
        .showType {
          color: $main_color;
          cursor: pointer;
          font-size: 13px;
        }
        .newadd {
          border: 0;
          font-size: 14px;

          .jiahao {
            font-size: 16px;
            margin-left: 9px;
            font-weight: 700;
            color: #56d79f;
          }
        }
        .line {
          width: 2px;
          height: 14px;
          background-color: #dedede;
        }
      }
    }
    // 右侧内容
    .details {
      position: relative;
      flex: 1;
      padding-left: 1px;
      overflow: auto;
      .on_off {
        position: absolute;
        top: 22px;
        left: 10px;
        cursor: pointer;
        i {
          font-size: 25px;
          color: #e8e8eb;
        }
        i:hover {
          color: #13cc74;
        }
      }
    }
  }
}
</style>
