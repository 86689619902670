<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-08 14:56:44
 * @LastEditors: 支磊
 * @LastEditTime: 2021-07-13 11:57:52
-->
<template>
  <div class="EquipmentLeftComponents">
    <div class="input">
      <el-input
        size="small"
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="filterText"
        clearable
      ></el-input>
      <div class="addGroup">
        <p @click.stop="popup()">
          <i class="el-icon-plus"></i>
        </p>
      </div>
    </div>
    <div class="tree">
      <div class="data">
        <el-tree
          :data="treedata"
          node-key="uId"
          ref="tree"
          default-expand-all
          :props="defaultProps"
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          @node-click="getnodename"
        >
          <div slot-scope="{ node, data }" class="custom-tree-node">
            <el-tooltip
              class="item"
              effect="dark"
              :content="node.label"
              placement="top"
              :open-delay="800"
            >
              <div class="names">
                <div class="circle_box" v-if="!data.chil">
                  <span class="circle"></span>
                </div>
                <div class="lable_name">{{ node.label }}</div>
              </div>
            </el-tooltip>
            <div
              class="more"
              v-if="data.level != 0"
              @click.stop="starF($event, data, node)"
            >
              <i class="el-icon-more"></i>
            </div>
          </div>
        </el-tree>
      </div>
    </div>

    <!-- 编辑框 -->
    <div
      class="listOperationBox"
      v-show="listOperationS"
      @click="listOperationS = false"
    >
      <div
        @click="selectOperationF"
        class="listOperation"
        :style="
          'left:' +
          listOperationPositon.left +
          'px;top:' +
          listOperationPositon.top +
          'px'
        "
      >
        <div data-type="edit">编辑分组</div>

        <div class="delate" data-type="delate">删除</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EquipmentLeftComponents",
  components: {},
  props: {
    treeData: {},
  },
  data() {
    return {
      listOperationS: false, //操作窗口是否显示
      is_root: false,
      treedata: [],
      filterText: "",
      defaultProps: {
        children: "chil",
        label: "name",
      },
      selectData: {},
      listOperationPositon: {
        //操作窗口位置
        left: 0,
        top: 0,
      },
    };
  },

  computed: {},
  //监听执行
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    treeData() {
      this.treedata = [...this.treeData];
      console.log("treeData", this.treeData);
    },
  },
  mounted() {},
  methods: {
    selectOperationF(event) {
      this.selecttype = event.target.dataset.type;

      this.$emit("emitF", this.selecttype, this.selectData);
    },
    // 点击三个小点的操作窗口
    starF($event, data, node) {
      if (data.id == this.treedata[0].id) {
        //点击的根项目
        this.is_root = true;
      } else {
        this.is_root = false;
      }
      this.selectData = { ...data };
      const { left, top } = $event.target.getBoundingClientRect();
      this.listOperationPositon = { left, top };
      this.listOperationS = true;
    },
    filterNode(value, data) {
      if (data.name.indexOf(value) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    popup() {
      this.$emit("emitF", "add");
    },
    // 获得节点名字。并传给父级
    getnodename(data) {
      console.log("datadatadatadata", data);
      this.$emit("node", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.EquipmentLeftComponents {
  font-size: 14px;
  height: 100%;
  width: 216px;
  /deep/.el-tree-node__expand-icon.expanded {
    font-size: 18px;
  }
  /deep/.el-tree-node__expand-icon {
    font-size: 18px;
  }
  .input {
    display: flex;
    padding-top: 5px;
  }
}
// 添加分组
.addGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: -10px;
  width: 32px;
  height: 32px;
  text-align: center;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 216px;
    height: 32px;
    cursor: pointer;
    color: #ffffff;
    background-color: #56d79f;
    border-radius: 6px;
    border: 1px solid #ffffff;
  }
  // p:hover {
  //   color: #13cc74;
  //   border: 1px solid #13cc74;
  // }
}
.tree {
  position: relative;
  // height: 100%;
  /deep/.is-leaf {
    display: none !important;
  }
  .data {
    padding-top: 10px;
    overflow: auto;
    max-height: calc(100% - 70px);
    min-height: 28px;
    /deep/.el-tree-node__content {
      height: 40px;
      padding-right: 10px;
    }
    .names {
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 6px;
      .circle_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        .circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ababab;
        }
      }

      .lable_name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
    }

    .custom-tree-node {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .more {
        display: none;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
      .el-icon-more:hover {
        color: $main_color;
      }
      .devnums {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        color: #ababab;
      }
      &:hover {
        .more {
          display: block;
        }
      }
    }
  }
}
.listOperationBox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .listOperation {
    position: absolute;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 8px 0;
    > div {
      padding: 6px 20px;
      cursor: pointer;
      &:hover {
        background-color: #eff0f1;
      }
    }
    .delate {
      color: #f54a45;
    }
  }
}
</style>
